.review {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 50px;
  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 769px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}
.reviews {
  padding-top: 0px;
  text-align: center;
  color: #b8b897;
  @media only screen and (max-width: 2500px) {
    height: 1280px;
  }
  @media only screen and (max-width: 1400px) {
    height: 1367px;
  }
  @media only screen and (max-width: 1200px) {
    height: 1605px;
  }
  @media only screen and (max-width: 992px) {
    height: 1573px;
  }
  @media only screen and (max-width: 991px) {
    height: 100%;
  }
  @media only screen and (max-width: 769px) {
    padding-bottom: 62px;
  }
  @media only screen and (max-width: 575px) {
    height: 100%;
    padding-bottom: 40px;
  }
  @media only screen and (max-width: 450px) {
    padding-top: 40px;
  }
  .header {
    h3 {
      margin-bottom: 40px;
    }
  }

  .card--2 {
    position: relative;
    @media only screen and (max-width: 992px) {
      top: 0;
    }
  }
  .card--1 {
    position: relative;

    @media only screen and (max-width: 992px) {
      top: 0;
    }
  }
  .card-0 {
    position: relative;

    @media only screen and (max-width: 992px) {
      top: -20px;
    }
    @media only screen and (max-width: 600px) {
      top: 0px;
    }
  }
  .card-1 {
    position: relative;
    top: -45px;
    @media only screen and (max-width: 1400px) {
      top: -61px;
    }
    @media only screen and (max-width: 1200px) {
      top: -80px;
    }
    @media only screen and (max-width: 992px) {
      top: -40px;
    }
    @media only screen and (max-width: 600px) {
      top: 0px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-2 {
    position: relative;
    top: -63px;
    @media only screen and (max-width: 1400px) {
      top: -82px;
    }
    @media only screen and (max-width: 1200px) {
      top: -121px;
    }
    @media only screen and (max-width: 992px) {
      top: -42px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-3 {
    position: relative;
    top: -6px;
    @media only screen and (max-width: 1400px) {
      top: -19px;
    }
    @media only screen and (max-width: 1200px) {
      top: -18px;
    }
    @media only screen and (max-width: 992px) {
      top: -162px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-4 {
    position: relative;
    top: -51px;
    @media only screen and (max-width: 1200px) {
      top: -61px;
    }
    @media only screen and (max-width: 1400px) {
      top: -81px;
    }
    @media only screen and (max-width: 1200px) {
      top: -98px;
    }
    @media only screen and (max-width: 992px) {
      top: -62px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-5 {
    position: relative;
    top: -109px;
    @media only screen and (max-width: 1400px) {
      top: -161px;
    }
    @media only screen and (max-width: 1200px) {
      top: -241px;
    }
    @media only screen and (max-width: 992px) {
      top: -182px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-6 {
    position: relative;
    top: -54px;
    @media only screen and (max-width: 1400px) {
      top: -100px;
    }
    @media only screen and (max-width: 1200px) {
      top: -138px;
    }
    @media only screen and (max-width: 992px) {
      right: -110%;
      top: -223px;
    }
    @media only screen and (max-width: 991px) {
      right: -115%;
      top: -223px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-7 {
    position: relative;
    top: -54px;
    @media only screen and (max-width: 1400px) {
      top: -100px;
    }
    @media only screen and (max-width: 1200px) {
      top: -112px;
    }
    @media only screen and (max-width: 992px) {
      right: 111%;
      top: -77px;
    }
    @media only screen and (max-width: 991px) {
      right: 114%;
      top: -77px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-8 {
    position: relative;
    top: -134px;
    @media only screen and (max-width: 1400px) {
      top: -197px;
    }
    @media only screen and (max-width: 1200px) {
      top: -277px;
    }
    @media only screen and (max-width: 992px) {
      right: -110%;
      top: -284px;
    }
    @media only screen and (max-width: 991px) {
      right: 0;
      top: -88px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
  .card-9 {
    position: relative;
    top: -79px;
    @media only screen and (max-width: 1400px) {
      top: -119px;
    }
    @media only screen and (max-width: 1200px) {
      top: -174px;
    }
    @media only screen and (max-width: 992px) {
      right: 111%;
      top: -93px;
    }
    @media only screen and (max-width: 991px) {
      right: 0%;
      top: -322px;
    }
    @media only screen and (max-width: 769px) {
      top: 0;
      right: 0;
    }
  }
}
