@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");

html {
  box-sizing: border-box;
  font-size: 16px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  font-family: "Oswald", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol,
ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}
body {
  position: relative;
  background: url(./images/bg/bg-pattern.png);
  background-size: contain;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
  }
}
.container {
  margin-left: auto;
  margin-right: auto;
  padding-right: 15px;
  padding-left: 15px;
  @media only screen and (min-width: 450px) {
    width: 400px;
  }
  @media only screen and (min-width: 576px) {
    width: 540px;
  }
  @media only screen and (min-width: 768px) {
    width: 720px;
  }
  @media only screen and (min-width: 992px) {
    width: 960px;
  }
  @media only screen and (min-width: 1200px) {
    width: 1140px;
  }
  @media only screen and (min-width: 1400px) {
    width: 1320px;
  }
}
.header {
  position: relative;
  svg {
    margin-bottom: -60px;
    text {
      letter-spacing: 10px;
      stroke: white;
      font-size: 80px;
      font-weight: 400;
      stroke-width: 1;
      animation: textAnimate 5s infinite alternate;
      @media only screen and (max-width: 450px) {
        font-size: 60px;
      }
    }
  }
  h3 {
    font-size: 13px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 11px;
    text-align: center;
    margin-bottom: 10px;
    color: #b8b897;
    span {
      color: #fff;
    }
  }
  .subtitle {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.2px;
    margin-top: 30px;
    margin-bottom: 40px;
    color: #dfdfdf;
    font-family: "Raleway", sans-serif;
  }
}

@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "./components/Navigation/navigation.scss";
@import "./components/Hero/Hero.scss";
@import "./components/Services/services.scss";
@import "./components/card/card.scss";
@import "./components/Reviews/reviews.scss";
@import "./components/reviewCard/reviewCard.scss";
@import "./components/About/about.scss";
@import "./components/iconDescription/iconDescription.scss";
@import "./components/Work/work.scss";
@import "./components/Contact/Contact.scss";
@import "./components/Footer/footer.scss";
