.reviewCard {
  width: 100%;
  figure.snip1192 {
    font-family: "Raleway", Arial, sans-serif;
    position: relative;
    overflow: hidden;
    margin: 10px;

    width: auto;
    color: #333;
    text-align: left;
    box-shadow: none !important;
    @media only screen and (max-width: 600px) {
      width: auto;
    }
  }
  figure.snip1192 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  figure.snip1192 img {
    max-width: 100%;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    margin-bottom: 15px;
    display: inline-block;
    z-index: 1;
    position: relative;
    opacity: 0.8;
  }
  figure.snip1192 blockquote {
    margin: 0;
    display: block;
    border-radius: 10px;
    position: relative;
    background-color: #141414;
    padding: 30px 50px 65px 50px;
    font-size: 0.8em;
    font-weight: 500;
    line-height: 1.6em;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    color: #b8b897;
  }
  figure.snip1192 blockquote:before,
  figure.snip1192 blockquote:after {
    font-family: "FontAwesome";
    content: "\201C";
    position: absolute;
    font-size: 50px;
    opacity: 0.3;
    font-style: normal;
  }
  figure.snip1192 blockquote:before {
    top: 35px;
    left: 20px;
  }
  figure.snip1192 blockquote:after {
    content: "\201D";
    right: 20px;
    bottom: 35px;
  }
  figure.snip1192 .author {
    margin: 0;
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    position: absolute;
    bottom: 20px;
    width: 100%;
    .name {
      padding: 0px 50px;
      display: flex;
      justify-content: space-between;
      .stars {
        display: flex;
        align-items: center;
        svg {
          height: 13px;
          color: #b8b897;
          padding: 0px 1.5px;
        }
      }
    }
  }
  figure.snip1192 .author h5 {
    opacity: 0.8;
    margin: 0;
    font-weight: 800;
    color: #b8b897;
  }
  figure.snip1192 .author h5 span {
    font-weight: 400;
    text-transform: none;
    display: block;
  }
}
