.hero-container {
  height: 100vh;
  .slick-slider {
    .slick-prev,
    .slick-next {
      color: #b8b897;
      z-index: 1;
      height: 40px;
      width: 40px;
      opacity: 0.3;
      &:hover {
        opacity: 0.6;
      }
      @media only screen and (max-width: 778px) {
        display: none !important;
      }
    }
    .slick-prev {
      left: 20px;
    }
    .slick-next {
      right: 20px;
    }
    .slick-dots {
      display: flex !important;
      height: 44px;
      bottom: -35px;
      align-items: center;
      justify-content: center;
      background-color: #141414;
      li {
        button {
          &:before {
            color: #b8b897;
            &.active {
              color: #fff;
            }
          }
        }
      }
    }
    .slick-slide {
      .slide {
        position: relative;
        &:after {
          content: "";
          position: absolute;
          background: rgba(0, 0, 0, 0.7);
          top: 0;
          right: 0;
          bottom: 0px;
          left: 0;
        }
        img {
          height: 89vh;
          width: 100%;
          object-fit: cover;
          object-position: top;
          margin-top: 8vh;
          position: relative;

          @media only screen and (max-width: 758px) {
            margin-top: 4vh;
            height: 93vh;
          }
          @media only screen and (max-width: 758px) {
            &.mobile-position-fix {
              object-position: 29% 100%;
            }
          }
        }
        .content {
          text-align: center;
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
          flex-direction: column;
          color: #b8b897;
          h1 {
            margin: 10px 0px;
            margin-bottom: 10px;
            text-align: center;
            font-weight: 100;
            text-transform: uppercase;
            letter-spacing: 15px;
            font-size: 90px;
            line-height: 100px;
            text-shadow: #b8b897 0.1px 0.1px 50px;
            @media only screen and (max-width: 1200px) {
              font-size: 30px;
              line-height: 30px;
            }
            @media only screen and (max-width: 778px) {
              letter-spacing: 10px;
              font-size: 25px;
              line-height: normal;
            }
            span {
              color: #fff;
            }
          }
          h2 {
            padding: 0px 15px;
            margin-top: 10px;
            letter-spacing: 2px;
            font-weight: 300;
            text-transform: uppercase;
            text-align: center;
            font-size: 16px;
            line-height: 22px;
            text-shadow: #fff 0.1px 0.1px 50px;
            @media only screen and (max-width: 778px) {
              font-size: 12px;
            }
          }
          .location {
            font-weight: 100;
            margin-bottom: 0;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 20px;
            font-size: 40px;
            line-height: 40px;
            @media only screen and (max-width: 1200px) {
              font-size: 20px;
              letter-spacing: 10px;
            }
            @media only screen and (max-width: 778px) {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}
