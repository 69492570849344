.contact {
  padding-bottom: 78px;
  .header {
    margin-bottom: 50px;
  }
  color: white;
  .address-form {
    position: relative;
    font-family: "Raleway", Helvetica, sans-serif;
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
    letter-spacing: 2px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .half-width {
      width: 100%;
      .address {
        text-align: right;
        .phone {
          margin-bottom: 11px;
          a {
            margin-left: 10px;
            color: #b8b897;
            text-decoration: none;
            &:before {
              width: 20px;
              text-align: right;
              margin-right: 5px;
              margin-left: -25px;
              content: "[";
              transform: translateX(20px);
              display: inline-block;
              opacity: 0;
              transition: transform 0.3s, opacity 0.2s;
            }
            &:after {
              width: 20px;
              text-align: left;
              margin-left: 5px;
              margin-right: -25px;
              content: "]";
              transform: translateX(-20px);
              display: inline-block;
              opacity: 0;
              transition: transform 0.3s, opacity 0.2s;
            }
            &:hover {
              &:before {
                opacity: 1;
                transform: translateX(0px);
              }
              &:after {
                opacity: 1;
                transform: translateX(0px);
              }
            }
          }
        }
        .company-address {
          margin-bottom: 11px;
          a {
            color: #b8b897;
            text-decoration: none;
          }
        }
        .email {
          margin-bottom: 11px;
          a {
            color: #b8b897;
            text-decoration: none;
          }
        }
        .social {
          display: flex;
          justify-content: end;
          flex-direction: row;
          a {
            color: white;
            text-decoration: none;
            &:hover {
              color: #b8b897;
            }
            svg {
              margin-left: 10px;
              font-size: 24px;
            }
          }
        }
      }
      .form-container {
        .form {
          .name-email-input {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            margin-bottom: 10px;
            @media only screen and (max-width: 415px) {
              grid-template-columns: 1fr;
            }
            input {
              font-family: "Raleway", Arial, sans-serif;
              color: white;
              background-color: transparent;
              border: 1px solid rgba(255, 255, 255, 0.4);
              outline-color: rgba(239, 239, 239, 0);
              padding: 10px;
              height: 30px;
              font-weight: 700;
              &:focus {
                outline: 1px solid rgba(239, 239, 239, 0.37);
              }
            }
          }
          .message {
            display: flex;
            textarea {
              font-family: "Raleway", Arial, sans-serif;
              color: white;
              resize: none;
              width: 100%;
              border: 1px solid rgba(255, 255, 255, 0.4);
              background-color: transparent;
              outline-color: rgba(239, 239, 239, 0);
              padding: 10px;
              font-weight: 700;
              &:focus {
                outline: 1px solid rgba(239, 239, 239, 0.37);
              }
            }
          }
          .btn-container {
            display: flex;
            justify-content: space-between;
            margin-top: 15px;
            p {
              font-size: 13px;
              padding: 10px 0px;
              padding-right: 10px;
              span {
                color: #b8b897;
              }
            }

            .submit {
              line-height: 22px;
              text-align: center;
              letter-spacing: 2px;
              color: #b8b897;
              text-transform: uppercase;
              font-family: "Oswald", Helvetica, sans-serif;
              background-color: transparent;
              border: 1px solid rgba(255, 255, 255, 0.4);
              outline-color: rgba(239, 239, 239, 0);
              padding: 10px;
              height: 44px;
              font-weight: 500;

              cursor: pointer;
              font-size: 13px;
              transition: all 0.3s;
              transition-property: all;
              transition-duration: 0.3s;
              transition-timing-function: ease;
              transition-delay: 0s;
              position: relative;

              &:before {
                transform: translateX(-50%) translateY(-50%);
                transition: all 0.3s;
                width: 100%;
                height: 0;
                top: 50%;
                left: 50%;
                opacity: 0;
                content: "";
                position: absolute;
                background-color: rgba(255, 255, 255, 0.13);
              }
              &:hover {
                &:before {
                  height: 100%;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
  .google-maps {
    margin-top: 80px;
    @media only screen and (max-width: 485px) {
      grid-template-columns: 1fr;
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}
