.navbar {
  @media only screen and (min-width: 778px) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
  }
  .desktop {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 5;
    .mobile-nav-button {
      height: 28px;
      width: 20px;
      cursor: pointer;
      position: relative;
      display: none;
      &.show {
        span {
          background-color: transparent;
          &:before {
            transform: translateY(0px) rotate(-45deg);
          }
          &:after {
            transform: translateY(0px) rotate(45deg);
          }
        }
      }
      @media only screen and (max-width: 778px) {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      span {
        cursor: pointer;
        position: absolute;
        width: 22px;
        height: 2px;
        background-color: #fff;
        &:before,
        &:after {
          transition: all 0.3s ease;
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          transform: translateY(-6px) rotate(0deg);
          background-color: #fff;
        }
        &:after {
          transform: translateY(6px) rotate(0deg);
        }
      }
    }
    @media only screen and (max-width: 778px) {
      line-height: 28px;
    }
    background-color: #141414;
    padding: 10px 0px;
    line-height: 68px;
    &_container {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &_logo {
      a {
        font-size: 20px;
        letter-spacing: 0.9px;
        font-weight: 800;
        color: #abab8d;
        text-decoration: none;
        @media only screen and (max-width: 778px) {
          font-size: 15px;
        }
        span {
          color: #fff;
          margin-left: -5px;
        }
      }
    }
    &_links {
      @media only screen and (max-width: 778px) {
        display: none;
      }
      ul {
        display: flex;
        div {
          display: flex;
          @media only screen and (max-width: 778px) {
            display: none;
          }
        }
        li {
          margin-left: 15px;
          margin-right: 15px;
          a {
            text-decoration: none;
            color: #abab8d;
            font-size: 18px;
            padding: 7px 15px;
            border: 1px solid transparent;
            border-radius: 50px;
            font-weight: 300;
            position: relative;
            font-size: 12px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 3px;
            @media only screen and (max-width: 992px) {
              padding: 7px 9px;
            }
            &:before {
              width: 20px;
              text-align: right;
              margin-right: 5px;
              margin-left: -25px;
              content: "[";
              transform: translateX(20px);
              display: inline-block;
              opacity: 0;
              transition: transform 0.3s, opacity 0.2s;
            }
            &:after {
              width: 20px;
              text-align: left;
              margin-left: 5px;
              margin-right: -25px;
              content: "]";
              transform: translateX(-20px);
              display: inline-block;
              opacity: 0;
              transition: transform 0.3s, opacity 0.2s;
            }
            &:hover {
              &:before {
                opacity: 1;
                transform: translateX(0px);
              }
              &:after {
                opacity: 1;
                transform: translateX(0px);
              }
            }
            &.active {
              &:before {
                opacity: 1;
                transform: translateX(0px);
              }
              &:after {
                opacity: 1;
                transform: translateX(0px);
              }
            }
          }
          // &.active {
          //   & a:before {
          //     opacity: 1;
          //     transform: translateX(0px);
          //   }
          //   & a:after {
          //     opacity: 1;
          //     transform: translateX(0px);
          //   }
          // }
          @media only screen and (max-width: 1000px) {
            margin-right: 0px;
            margin-left: 0px;
          }
        }
      }
    }
    &_cta {
      @media only screen and (max-width: 778px) {
        display: none;
      }
      a {
        color: #abab8d;
        text-decoration: none;
        position: relative;
        transition: all 0.3s;
        border-style: solid;
        border-width: 1px;
        line-height: 22px;
        padding: 8px 12px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 3px;
        border-color: rgba(255, 255, 255, 0.3);
        border-radius: 50px;
        svg {
          margin-right: 10px;
          animation: ring 4s 0.7s ease-in-out infinite;
        }

        &:before {
          transform: translateX(-50%) translateY(-50%);
          border-radius: 50px;
          transition: all 0.3s;
          width: 100%;
          height: 0;
          top: 50%;
          left: 50%;
          opacity: 0;
          content: "";
          position: absolute;
          background-color: rgba(255, 255, 255, 0.13);
        }
        &:hover {
          &:before {
            height: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
  .mobile {
    &-cta {
      position: absolute;
      bottom: 25px;
      right: 0;
      width: 100%;
      text-align: center;
      a {
        color: #abab8d;
        text-decoration: none;
        position: relative;
        transition: all 0.3s;
        border-style: solid;
        border-width: 1px;
        line-height: 22px;
        padding: 8px 12px;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 3px;
        border-color: rgba(255, 255, 255, 0.3);
        border-radius: 50px;
        svg {
          margin-right: 10px;
          animation: ring 4s 0.7s ease-in-out infinite;
        }

        &:before {
          transform: translateX(-50%) translateY(-50%);
          border-radius: 50px;
          transition: all 0.3s;
          width: 100%;
          height: 0;
          top: 50%;
          left: 50%;
          opacity: 0;
          content: "";
          position: absolute;
          background-color: rgba(255, 255, 255, 0.13);
        }
        &:hover {
          &:before {
            height: 100%;
            opacity: 1;
          }
        }
      }
    }
    transition: all 0.3s ease-in-out;
    right: -100%;
    background-color: #212124;
    width: 240px;
    padding-top: 30px;
    position: fixed;
    top: 48px;
    bottom: 0;
    height: auto;
    z-index: 100;
    visibility: hidden;
    pointer-events: none;
    &.show {
      right: 0;
      visibility: visible;
      pointer-events: auto;
      @media only screen and (min-width: 778px) {
        visibility: hidden;
        pointer-events: none;
        right: -100%;
      }
    }

    nav {
      padding: 15px;
      ul {
        li {
          margin-right: 15px;
          margin-left: 15px;
          a {
            text-decoration: none;
            color: #afafaf;
            display: block;
            font-size: 14px;
            line-height: 2;
            padding: 5px 0;
            &:before {
              width: 20px;
              text-align: right;
              margin-right: 5px;
              margin-left: -25px;
              content: "[";
              transform: translateX(20px);
              display: inline-block;
              opacity: 0;
              transition: transform 0.3s, opacity 0.2s;
            }
            &:after {
              width: 20px;
              text-align: left;
              margin-left: 5px;
              margin-right: -25px;
              content: "]";
              transform: translateX(-20px);
              display: inline-block;
              opacity: 0;
              transition: transform 0.3s, opacity 0.2s;
            }
            &:hover {
              &:before {
                opacity: 1;
                transform: translateX(0px);
              }
              &:after {
                opacity: 1;
                transform: translateX(0px);
              }
            }
          }
        }
      }
    }
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  5% {
    transform: rotate(30deg);
  }
  10% {
    transform: rotate(-28deg);
  }
  15% {
    transform: rotate(34deg);
  }
  20% {
    transform: rotate(-32deg);
  }
  25% {
    transform: rotate(30deg);
  }
  30% {
    transform: rotate(-28deg);
  }
  35% {
    transform: rotate(26deg);
  }
  40% {
    transform: rotate(-24deg);
  }
  45% {
    transform: rotate(22deg);
  }
  50% {
    transform: rotate(-20deg);
  }
  55% {
    transform: rotate(18deg);
  }
  60% {
    transform: rotate(-16deg);
  }
  65% {
    transform: rotate(14deg);
  }
  70% {
    transform: rotate(-12deg);
  }
  75% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(-8deg);
  }
  85% {
    transform: rotate(6deg);
  }
  90% {
    transform: rotate(-4deg);
  }
  95% {
    transform: rotate(2deg);
  }
  100% {
    transform: rotate(-1deg);
  }
}
