.services {
  padding-top: 75px;
  padding-bottom: 75px;
  text-align: center;

  color: #b8b897;

  @media only screen and (max-width: 450px) {
    padding-top: 40px;
    padding-bottom: 0px;
  }
  .h3 {
    margin-bottom: 10px;
  }
  .subtitle {
    margin-top: 10px;
  }

  .service-card {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    grid-gap: 50px;
    @media only screen and (max-width: 992px) {
      grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  @keyframes textAnimate {
    0% {
      stroke-dasharray: 0 50%;
      stroke-dashoffset: 20%;
      fill: hsl(60, 19%, 66%);
    }

    100% {
      stroke-dasharray: 50% 0;
      stroke-dashoffstet: -20%;
      fill: hsla(189, 68%, 75%, 0%);
    }
  }
}
