.about {
  .header {
    h3 {
      margin-bottom: 40px;
    }
  }
  .about-content {
    display: flex;
    position: relative;
    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }
    .image-container {
      width: 50%;
      @media only screen and (max-width: 992px) {
        width: 100%;
      }
      .image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.7;
      }
    }
    .content {
      width: 50%;
      text-align: center;
      color: white;
      padding: 5vw 5vw;
      line-height: 23px;
      background-color: #141414;
      font-family: "Raleway", Arial, sans-serif;
      letter-spacing: 1px;
      display: flex;
      align-items: center;
      font-size: 15px;
      flex-direction: column;
      justify-content: center;
      font-weight: 300;
      @media only screen and (max-width: 992px) {
        width: auto;
      }
      @media only screen and (max-width: 768px) {
        padding: 50px 5vw;
      }
      .span {
        display: block;
        margin-top: 10px;
        color: #b8b897;
        font-weight: 400;
        font-family: "Oswald", sans-serif;
        font-size: 17px;
      }
      .icons {
        display: flex;
        width: 100%;
        margin-top: 70px;
        justify-content: space-evenly;
        @media only screen and (max-width: 768px) {
          margin-top: 30px;
        }
      }
    }
  }
}
