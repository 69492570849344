$desktop: 1024px;

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: $desktop) {
      @content;
    }
  }
}

.card {
  border-radius: 10px;
  position: relative;
  width: 100%;
  &:before {
    content: "";
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 100%,
      rgba(255, 255, 255, 0) 40%
    );
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    z-index: 1;
  }

  &:hover {
    .card__caption {
      top: 50%;
      transform: translateY(-50%);
    }

    .card__image {
      transform: scale(1.2);
    }

    .card__thumb {
      &::after {
        top: 0;
      }
    }

    .card__snippet {
      margin: 20px 0;
    }
  }

  &__thumb {
    border-radius: 10px;
    position: relative;
    max-height: 400px;
    overflow: hidden;
    margin: 0px;
    width: 100%;
    height: 115%;

    @include breakpoint(desktop) {
      width: 100%;
      margin: 0;
    }

    &::after {
      position: absolute;
      top: 0;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 40%,
        rgba(255, 255, 255, 0) 100%
      );
      transition: 0.3s;

      @include breakpoint(desktop) {
        top: calc(100% - 140px);
      }
    }
  }

  &__image {
    height: 400px;
    object-fit: cover;
    transition: 0.5s ease-in-out;
    width: 100%;
  }

  &__caption {
    position: absolute;
    top: 50%;
    z-index: 1;
    padding: 0 20px;
    color: white;
    transform: translateY(-50%);
    text-align: center;
    transition: 0.3s;

    @include breakpoint(desktop) {
      top: calc(100% - 42px);
      transform: unset;
    }
  }

  &__title {
    display: -webkit-box;
    max-height: 85px;
    overflow: hidden;
    font-family: "Playfair Display", serif;
    font-size: 23px;
    line-height: 28px;
    text-shadow: 0px 1px 5px black;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    @media only screen and (max-width: 1200px) {
      font-size: 20px;
    }
  }

  &__snippet {
    display: block;
    height: auto;
    margin: 20px 0;
    overflow: hidden;
    font-family: "Raleway", Arial, sans-serif;
    font-size: 16px;
    line-height: 20px;
    text-overflow: ellipsis;
    transition: 0.5s ease-in-out;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    @media only screen and (max-width: 1124px) {
      font-weight: 200;
    }

    @include breakpoint(desktop) {
      margin: 20px 0;
    }
  }

  &__button {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    border: 1px solid white;
    font-family: "Raleway", Arial, sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s;

    &:hover {
      color: black;
      background-color: white;
    }
  }
}

.disclaimer {
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 2;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 10px;
  background-color: white;
  transform: translateX(-50%);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  text-align: center;

  &__link {
    color: #755d87;
    text-decoration: none;
  }
}
