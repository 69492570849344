@import "~react-image-gallery/styles/scss/image-gallery.scss";
.work {
  padding-bottom: 50px;
  @media only screen and (max-width: 450px) {
    padding-bottom: 21px;
  }
  .header {
    padding-top: 80px;
    @media only screen and (max-width: 450px) {
      padding-top: 47px;
    }
    h3 {
      padding-bottom: 15px;
    }
  }
}
.gallery {
  .image-gallery-icon {
    color: #b8b897;
    opacity: 0.6;
  }
  .image-gallery-svg {
    width: 40px;
  }
  .image-gallery-thumbnail {
    .image-gallery-thumbnail-image {
      object-fit: cover;
      height: 70px;
    }
    &.active,
    &:focus,
    &:hover {
      border-color: #b8b897;
    }
  }
}
